<template>
  <div>
    <div class="circle" v-if="windowWidth > 991 && showCircleTooltip"></div>
    <div class="product-head">
      <div class="product-head-send text-center d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <div @click="selectObject(apartment)" class="btn-filter d-block">
            Поділитися
          </div>
          <svg
            class="icon question-icon ml-2"
            :id="`tooltip-target-share-${apartment.id}`"
          >
            <use xlink:href="@/assets/icons/question.svg#question"></use>
          </svg>
          <b-tooltip
            :target="`tooltip-target-share-${apartment.id}`"
            triggers="hover"
          >
            При натисканні на кнопку буде скопійовано посилання на об'єкт,
            який ви можете надсилати своїм клієнтам, контактні дані будуть замінені на Ваші!
          </b-tooltip>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="btn-filter d-block btn-red"
            style="transform: none"
            v-if="$route.name === 'archive'"
          >
            Не актуально
          </div>
          <div :class="{'circle-arhive': (windowWidth < 991 && showCircleTooltip)}" v-else>
            <b-dropdown
              id="dropdown-right"
              right
              text="Перенести до архіву"
              class="m-2 dropdown-archive"
              size="sm"
            >
              <b-dropdown-item
                href="#"
                @click="transferToArchive('rent_or_sold')"
                class="arhive-item"
                >{{
                  apartment.business_type === 'rent' ? 'Здано' : 'Продано'
                }}</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="transferToArchive('withdrawn_from_sale')"
                class="arhive-item"
                >{{
                  apartment.business_type === 'rent'
                    ? 'Зняли з оренди'
                    : 'Знято з продажу'
                }}</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="transferToArchive('there_is_from_owner')"
                class="arhive-item"
              >Є від власника</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="transferToArchive('incorrect')"
                class="arhive-item"
                >Не коректна інфо</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="transferToArchive('not_cooperate')"
                class="arhive-item"
                >Не співпрацюють</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="transferToArchive('not_respond')"
                class="arhive-item"
                >Не виходять на зв'язок</b-dropdown-item
              >
            </b-dropdown>
            <p class="cirle-title" v-if="showCircleTooltip">
              Цей об'єкт вже може бути {{ apartment.business_type === 'rent' ? 'зданий' : 'проданий' }}, скоро ми це перевіримо ;) <br>
              <span class="cirle-ok" @click="showCircleTooltip = false">
                OK
              </span>
            </p>
          </div>
          <svg class="icon question-icon ml-2" id="tooltip-target-archive" v-if="windowWidth > 991 || (!showCircleTooltip || apartment.is_archive !== '1')">
            <use xlink:href="@/assets/icons/question.svg#question"></use>
          </svg>
          <b-tooltip target="tooltip-target-archive" triggers="hover">
            Якщо знаєте, що квартира не актуальна, натисніть на червону
            кнопку і ми приберемо її в архів (За інформацію платимо 2 Easycoin).
          </b-tooltip>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div class="block-id">
          <span>id: {{ apartment.id }}</span>
        </div>
        <div class="block-id" style="margin-right: 37px" v-if="edit">
          <!-- <button @click="$router.push(`/manage-object/${apartment.id}`)" class="btn btn-filter">Редагувати</button> -->
          <button @click="EDIT_OBJECT(apartment); $emit('edit-object')" class="btn btn-filter">Редагувати</button>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-3">
          <div class="product-head-label">Добавлено:</div>
          <div class="product-head-agent-label">
            {{ apartment.created_at != null ? apartment.created_at : '' }}
          </div>
        </div>
        <div class="col-9">
          <div class="product-head-label d-flex">
            <span>Джерело: </span>
            <span class="ml-1">
              <template v-if="windowWidth < 991">
                {{
                  apartment.source === 'rieltor' ? 'Рієлтор' : 'Власник  - '
                }}
              </template>
              <template v-if="apartment.author_fname && apartment.author_lname">
                {{ apartment.author_fname + ' ' + apartment.author_lname }}
              </template>
              <template v-else>
                {{ apartment.agency }}
              </template>
            </span>
          </div>
          <div class="product-head-agent-label">
            <div
              class="phones-item"
              v-for="(phone, i) in apartment.phone"
              :key="i"
            >
              <a :href="`tel:${getPhone(phone)}`">{{ getPhone(phone) }}</a>
            </div>
          </div>
        </div>
        <template v-if="apartment.type === 'house'">
          <span class="product-hr"></span>
          <div
            class="comunication-row row w-100"
            v-if="apartment.type === 'house'"
          >
            <div class="col-6">
              <p class="mb-0"><b>Газ: </b>{{ gas_object[apartment.gas] }}</p>
              <p class="mb-0">
                <b>Вода: </b>{{ water_object[apartment.water] }}
              </p>
            </div>
            <div class="col-6">
              <p class="mb-0">
                <b>Каналізація: </b>{{ sewers_object[apartment.sewers] }}
              </p>
              <p class="mb-0">
                <b>Опалення: </b>{{ heating_object[apartment.heating] }}
              </p>
            </div>
          </div>
        </template>
        <template v-if="apartment.type === 'commerce'">
          <span class="product-hr"></span>
          <div
            class="comunication-row row w-100"
          >
           <div v-for="option in optionsList" :key="option" class="col-6">
              <p class="mb-0"><b> {{ option }} </b></p>
            </div>

          </div>
        </template>
      </div>
    </div>
    <span class="product-hr" />
    <p>
      {{ apartment.text }}
    </p>
    <div style="position: relative">
      <a
        :href="linksExport"
        v-on.stop.prevent="exportImages(apartment.id)"
        download
        class="d-flex product-download-container"
        v-if="apartment.is_used_for_advertising || !isBaseRoute || isSourceOwner"
      >
        <div class="product-download-btn">
          <icon name="download_icon" class="product-download-icon" />
        </div>
        <span class="product-download-label">Скачати всі фото архівом</span>
      </a>
    </div>
    <VueSlickCarousel v-bind="settings">
      <template v-if="imagesCollection.length > 0">
        <div
          v-for="(photo, photoIndex) in imagesCollection.length > 0
            ? imagesCollection
            : images"
          :key="photoIndex"
        >
          <img
            v-lazy="photo"
            @click="showBigPhoto(photoIndex)"
            :src="photo"
            class="preview-img"
            :class="{ 'denied-download-img': (isBaseRoute && !apartment.is_used_for_advertising) }"
          />
          <icon
            class="icon-full"
            @click="showBigPhoto(photoIndex)"
            name="fullscreen_icon"
            width="30"
            height="30"
          />
        </div>
      </template>
      <template v-else>
        <div><img src="@/assets/images/no-image.png" /></div>
      </template>
    </VueSlickCarousel>

    <div class="block-marg-xs"></div>
    <div class="product-comm">
      <textarea
        :id="'comment-' + apartment.id"
        v-model="comment"
        name="c"
        class="partner-review-control"
        placeholder="Додати коментар (видно тільки мені)"
        rows="20"
      >
      </textarea>
      <div class="block-marg-xs"></div>
      <div
        class="d-flex justify-content-end align-items-center"
        @click="addComment"
      >
        <div class="btn-filter btn-lg">Надіслати</div>
      </div>
    </div>
    <div
      class="partner-review-box"
      v-for="(item, index) in getComments"
      :key="index"
    >
      <div class="partner-review-title d-flex justify-content-between">
        <div class="d-flex w-100">
          <div class="mr-auto">
            <b>Добавлено: </b><br /><span class="partner-review-date">
              {{ item.get_date }}</span
            >
          </div>
        </div>
      </div>
      <p class="partner-review-content">
        {{ item.text }}
      </p>
    </div>

    <div class="text-center">
      <button
        class="btn-filter btn btn-lg d-none d-lg-inline-block"
        @click="selectObject(apartment)"
      >
        Поділитися об'єктом
      </button>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import LabelsMixin from '@/mixins/LabelsMixin';
import { mapActions, mapMutations } from 'vuex';
import { ADDITIONS } from '@/helpers/constants';
import { getRouterKey } from '@/helpers/routerKey.js'
import api from '@/api';
import { getHiddenPhone } from '@/helpers/formats';

export default {
  name: 'CatalogCardInfo',
  data: () => {
    return {
      settings: {
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      comment: '',
      linksExport: '',
      showCircleTooltip: false
    };
  },
  mixins: [LabelsMixin],
  components: {
    VueSlickCarousel
  },
  methods: {
    ...mapActions(['handleObject', 'handleAddCommentToShare']),
    ...mapMutations(['EDIT_OBJECT']),
    getPhone(phone){
      if(this.apartment.is_archive == '2' && this.apartment.source === 'owner'){
        return getHiddenPhone(phone);
      }
      return phone;
    },
    exportImages(id) {
      this.linksExport = `https://api.easybase.com.ua/v1/realty/${id}/image/all/`;
    },
    selectObject(object) {
      this.$copyText(
        `${process.env.VUE_APP_URL_EASYHATA}/${getRouterKey(object.type)}/${object.id}/rieltor/${object.share}`
      ).then(() => {
        this.$notify({
          group: 'app',
          type: 'success',
          text: 'Посилання на об\'єкт скопійовано'
        });
      });
    },
    async transferToArchive(type) {
      try {
        const response = await api.reportArchive(this.apartment.id, type, this.apartment.type);

        if (response.status === 200) {
          this.$notify({
            group: 'app',
            type: 'success',
            text:
              'Дякуємо за інформацію, незабаром обов\'язково її перевіримо'
          });
        }
      } catch (e) {
        if (e.response.status === 400) {
          Object.values(e.response.data).forEach((val) => {
            this.$notify({ group: 'app', type: 'error', text: val });
          });
        }
      }
    },
    addComment() {
      const data = {
        id: this.apartment.id,
        comment: this.comment
      };
      this.handleAddCommentToShare(data);
      this.comment = '';
    },
    showBigPhoto(index) {
      this.$emit('show-lightbox', index);
    },
    showMessage() {
      this.$notify({
        group: 'app',
        type: 'success',
        text: 'Посилання на об\'єкт скопійовано'
      });
    },
    checkShowingCirleTooltip() {
      if (this.apartment.realtyreports_set) {
        this.apartment.realtyreports_set.forEach(realtyreport => {
          if (realtyreport.is_processed === false
            && (realtyreport.reason === 'rent_or_sold'|| realtyreport.reason === 'withdrawn_from_sale'))
          {
            this.showCircleTooltip = true;
          }
        });
      }
    }
  },
  computed: {
    images: () => {
      return [{ srcset: require('@/assets/images/' + 'no-image.png') }];
    },
    imagesCollection() {
      return this.apartment.images.map((a) => a.img_obj);
    },
    carText: () => [
      '<svg viewBox="0 0 22 22" class="owl-nav-left svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>',
      '<svg viewBox="0 0 22 22" class="owl-nav-right svg-icon svg-fill"><path d="M21 5.6L11 16.4 1 5.6h20z"></path></svg>'
    ],
    getComments() {
      const comments = [...this.apartment.comments];

      return comments.reverse();
    },
    isBaseRoute() {
      if (this.$route
        && this.$route.name
        && ['home', 'search'].includes(this.$route.name)
      ) {
        return true;
      }

      return false;
    },
    optionsList() {
      return this.apartment.options && this.apartment.options.map(i => ADDITIONS[i])
    },
    isSourceOwner() {
      return this.$store.state.filter_source.form.source
        .includes('owner');
    }
  },
  created() {
    this.checkShowingCirleTooltip();
  },
  props: {
    apartment: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: () => {}
    },
    windowWidth: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style>
.icon-full {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 15px;
  z-index: 11;
}
.icon-full path {
  fill: #ffffff;
}
.vgs__gallery {
  display: none;
}
.vgs__container {
  transform: translateY(-50%);
  top: 50%;
  margin-top: 0;
}
.vgs__prev {
  left: 10%;
}
.vgs__next {
  right: 10%;
}
.wrapper-edit {
  margin-left: auto;
}
.comunication-row {
  padding-left: 15px;
}
.question-icon {
  width: 20px;
  height: 20px;
}
@media (max-width: 767px) {
  .vgs__prev {
    left: 0%;
  }
  .vgs__next {
    right: 0%;
  }
}

.block-id {
  padding-bottom: 10px;
}

.arhive-item {
  font-size: 13px;
}

.dropdown-archive > button {
  background-color: red;
  border: none;
  border-radius: 10px;
  z-index: 6000;
}

.dropdown-archive > button:hover {
  background-color: #c90a0a;
  color: white;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: red;
}

.preview-img {
  max-height: 480px;
}

.vgs__container > img, .denied-download-img {
  pointer-events: none;
}

.circle {
  position: absolute;
  width: 250px;
  height: 200px;
  background: #308b9d;
  top: -44px;
  right: -11px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  z-index: 5000;
  opacity: 0.9;
}

.circle-arhive {
  padding-top: 20px;
  background-color: #308b9d;
  height: 140px;
  width: 221px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  margin-right: -10px;
}

.cirle-title {
  position: absolute;
  z-index: 9999999;
  font-size: 13px;
}

.cirle-ok {
  position: relative;
  z-index: 9999999;
  top: 10px;
}

.cirle-ok:hover {
  cursor: pointer;
}

#dropdown-right {
  z-index: 9999999999;
}

</style>
